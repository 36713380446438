import { useCallback, useEffect, useState } from "react";
import ChatBox from "./ChatBox";
import { v4 as uuidv4 } from "uuid";
import { MessageService } from "../services/chat.service";
import { Message } from "../types/Message";
import { SESSION_KEY } from "../constants";
import { useCookies } from "react-cookie";
import LoadingScreen from "./LoadingScreen";
import moment from "moment";
import { MessageRO } from "../types/MessageRO";
import { Agent } from "../types/Agent";

const Chat = ({ isEmbedded, agentUuid }: { isEmbedded: boolean, agentUuid: string }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [chatContainer, setChatContainer] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [agentInfo, setAgentInfo] = useState<Agent>();
  const [currentBookmark, setCurrentBookmark] = useState<string>();
  const [identifier, setIdentifier] = useState<string>();
  const [bottomSpacing, setBottomSpacing] = useState<number>(0);

  const [cookies, setCookie] = useCookies([SESSION_KEY]);

  useEffect(() => {
    if (!cookies[SESSION_KEY]) {
      console.debug("No identifier found, creating new one");
      generateNewIdentifier();
    } else {
      console.debug("Identifier found, using existing one");
      setIdentifier(cookies[SESSION_KEY]);
    }
  }, []);

  const generateNewIdentifier = useCallback(() => {
    let newIdentifier = uuidv4();
    setCookie(SESSION_KEY, newIdentifier, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) });
    setIdentifier(newIdentifier);
    console.log("generateNewIdentifier", newIdentifier);
  }, []);

  useEffect(() => {
    const fetchAgentInfo = async (id: string) => {
      const infoResponse = await MessageService.getBotInfo(id);
      setAgentInfo(infoResponse.data);
      if (!isEmbedded) {
        document.title = infoResponse.data.name;
        let link = document.querySelector("link[rel~='icon']") as any;
        if (!link) {
          link = document.createElement('link') as any;
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.href = infoResponse.data.botImgUrl || `${process.env.REACT_APP_STATIC_FILE_PATH}/logo192.png`;
      }
    };
    if (agentUuid) {
      try {
        fetchAgentInfo(agentUuid);
      } catch (error) {
        console.error("Error fetching agent info:", error);
      }
    }
  }, [agentUuid]);

 
  const onUserMessageAdded = async (messageText: any) => {
    console.debug("message text: " + messageText);
    // add a dummy message to the chat to fake message sent, will be removed after durning next fetch
    let dummyId = "dummy-" + uuidv4();
    const dummyMessage: Message = {
      id: dummyId,
      role: "user",
      content: messageText,
      createdAt: moment().toISOString(),
      updatedAt: moment().toISOString(),
    };
    setMessages((messages: Message[]) => [...messages, dummyMessage]);
    await MessageService.sendMessages({
      bot_uuid: agentUuid, // Use extracted botUuid
      channel_type: "web",
      channel_id: agentUuid, // Use extracted botUuid
      thread_id: identifier,
      api_key: "94b9b5db9954ff5db891f8e8eea721f5",
      prompt: messageText,
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        fetchMessages(false);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }, 2000); // Fetch messages every 2 seconds

    return () => {
      clearInterval(intervalId);
    };
  }, [identifier]);

  /**
   * Fetch messages from the server
   * @param initialLoad - Whether this is the initial load, load 100 latest messages
   * @param bookmark - The bookmark to fetch messages from the bookark, use to fetch older messages
   */
  const fetchMessages = useCallback(async (initialLoad: boolean = false, bookmark?: string) => {
    if (!agentUuid || !identifier) {
      console.error("Agent UUID or identifier not found");
      return;
    }
    console.debug("Fetching messages at bookmark: ", bookmark);
    try {
      let query = {
        bot_uuid: agentUuid, // Use extracted botUuid
        channel_type: "web",
        channel_id: agentUuid, // Use extracted botUuid
        thread_id: identifier,
        api_key: "94b9b5db9954ff5db891f8e8eea721f5",
        limit: initialLoad ? 100 : 10,
        bookmark: initialLoad ? undefined : bookmark, // fetch latest messages if initial load, otherwise fetch from bookmark
      };
      const response = await MessageService.getMessages(query);
      let transformedMesssages: Message[] = [];
      response?.data?.data?.forEach((message: MessageRO) => {
        if (message.userMsgId) {
          transformedMesssages.push({
            id: message.id,
            role: "user",
            content: message.userMsg || "",
            createdAt: message.createdAt,
            updatedAt: message.updatedAt,
          });
        }
        if (message.botMsgId) {
          transformedMesssages.push({
            id: message.id,
            role: "assistant",
            content: message.botMsg || "",
            createdAt: message.createdAt,
            updatedAt: message.updatedAt,
          });
        }
      });
      if (initialLoad || bookmark) {
        console.debug("Next bookmark: ", response.data.nextBookmark);
        setCurrentBookmark(response.data.nextBookmark);
      }

      setMessages((currentMessages: Message[]) => {
        const newMessages = transformedMesssages.filter(newMsg =>
          !currentMessages.some(existingMsg =>
            existingMsg.id === newMsg.id && existingMsg.role === newMsg.role
          )
        );
        if (newMessages.length > 0) {
          // remove dummy messages
          let messageWithoutDummies = currentMessages.filter(msg => !msg.id.startsWith("dummy-"));
          if (bookmark) {
            return [...newMessages, ...messageWithoutDummies];
          }
          return [...messageWithoutDummies, ...newMessages];
        }

        return currentMessages;
      });
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }, [identifier]);

  const onScrollReachTop = async () => {
    console.debug("On scroll reach top, fetching messages from bookmark: ", currentBookmark);
    await fetchMessages(false, currentBookmark);
  };

   const onRequestNewConversation = async () => {
    generateNewIdentifier();
    setMessages([]);
  };


  const onLoad = async () => {
    console.debug("Initial load, fetching messages from server");
    await fetchMessages(true, undefined);
    (window as any).top.postMessage({ event: "chat:loaded" }, "*");
    console.debug("Chat loaded");
    setIsLoading(false);
  };

  useEffect(() => {
    if (identifier && agentInfo) {
      onLoad();
    }
  }, [identifier, agentInfo]);

  // Utility function to detect mobile devices
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  useEffect(() => {
    const spacing = isEmbedded || isMobileDevice() ? 0 : 20;
    setBottomSpacing(spacing);
  }, [isEmbedded]);

  return <div className="">
    <div className="flex bg-transparent">
      <div
        className="flex-grow rounded-md"
        style={{
          height: `calc(100vh - ${bottomSpacing}px - ${window.scrollY + chatContainer
            ? chatContainer.getBoundingClientRect().top
            : 0
            }px)`,
        }}
        ref={(el) => setChatContainer(el)}
      >
        {isLoading ? (
          <LoadingScreen />
        ) : !agentInfo ? (
          <div className="text-center text-gray p-5">Agent is not found.</div>
        ) : (
          <ChatBox
            agentInfo={agentInfo}
            messages={messages}
            onUserMessageAdded={onUserMessageAdded}
            onRequestNewConversation={onRequestNewConversation}
            onScrollReachTop={onScrollReachTop}
          />
        )}
      </div>
    </div>
  </div>
}

export default Chat;
