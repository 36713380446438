import { ApiResponse, BaseApiService } from "./base.service";
import qs from "qs";

export class MessageAPIFactory extends BaseApiService {
  getMessages(query: any): Promise<ApiResponse> {
    return this.post(`/v1/chat/history`, query);
  }

  sendMessages(inputs: any): Promise<ApiResponse> {
    return this.post(`/v1/chat/completions`, inputs);
  }

  getBotInfo(uuid: string): Promise<ApiResponse> {
    return this.get(`/v1/chat/agent-info?botUuid=${uuid}`);
  }
}

export const MessageService = new MessageAPIFactory();
