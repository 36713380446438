import Markdown from "react-markdown"
import { Message } from "../types/Message"
import remarkGfm from 'remark-gfm'
import moment from "moment"
import rehypeRaw from "rehype-raw";

const AssistantMessage = ({ message }: { message: Message } = { message: {} as Message }) => {
    const markdownText = message.content.replace(/\\n/gi, "\n").replace(/\n/gi, "<br/>");
    const isClearContext = message.content === 'CLEAR_CONTEXT';

    // Extract image URLs from the message content
    const imageUrls = message.content?.match(/(https?:\/\/[^\s]+(?=\.(?:png|jpg|jpeg|gif)|(?=\?|\#)))/gi) || [];

    console.log(message.content, imageUrls)

    return (
        isClearContext ? <div className="flex justify-center w-4/5 relative p-1 my-8 h-5 mx-auto cursor-default select-none">
            <div className="border-b border-dashed border-gray-300  w-1/2"></div>
            <div style={{
                top: '14px'
            }} className="px-2 text-sm text-gray-500  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">Context Cleared</div>
            <div className="border-b border-dashed border-gray-300  w-1/2"></div>
        </div> : <div className="flex items-end m-2 w-3/4">
            <div className="my-1 sm:w-3/4 md:w-3/4">
                {/* <div className="text-xs text-gray-100 dark:text-gray-200 pb-1">
                    Assistant
                </div> */}
                <div className="p-3 text-sm text-gray-700 bg-gray-200 rounded-2xl rounded-bl-none ">
                    <Markdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                            a: ({ node, ...props }) => <a {...props} target="_blank" className="text-blue-600 hover:underline" />
                        }}
                    >
                        {markdownText}
                    </Markdown>
                </div>
                <div className="text-xs text-gray-500 pt-1 select-none">
                    {moment(message.updatedAt).fromNow()}
                </div>
            </div>
        </div>)
}

export default AssistantMessage



