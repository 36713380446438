import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import AssistantMessage from "./AssistantMessage";
import UserMessage from "./UserMessage";
import { Message } from "../types/Message";
import TextareaAutosize from "react-textarea-autosize";
import { Agent } from "../types/Agent";
import { Transition } from "@headlessui/react";
import { Menu } from "@headlessui/react";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

const ChatBox = ({
    messages,
    onUserMessageAdded,
    onRequestNewConversation,
    onScrollReachTop,
    agentInfo,
}: {
    messages: Message[];
    onUserMessageAdded: any;
    onRequestNewConversation: any;
    onScrollReachTop: any;
    agentInfo?: Agent;
}) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [chatContainer, setChatContainer] = useState<any>();
    const [isSendingMessage, setIsSendingMessage] = useState<any>();
    const [shouldShowTypingMessage, setShouldShowTypingMessage] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [newMessageIndicator, setNewMessageIndicator] = useState(false);
    const [copyright, setCopyright] = useState("");

    // Utility function to detect mobile devices
    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    useEffect(() => {
        // check if last message is assistant message
       if (messages.length > 0 && messages[messages.length - 1].role === "assistant") {
        console.log("Last message is assistant message, setting shouldShowTypingMessage to false", messages);
        setShouldShowTypingMessage(false);
       }
    }, [messages]);

    useEffect(() => {
        if (isAtBottom && chatContainer) {
            chatContainer.scrollTo({ top: chatContainer.scrollHeight + 32, behavior: 'instant' });
        } else {
            setNewMessageIndicator(true);
        }
    }, [messages, chatContainer, copyright]);

    const handleScroll = useCallback(() => {
        if (chatContainer) {
            const threshold = 50;
            const isUserAtBottom =
                chatContainer.scrollHeight - chatContainer.scrollTop <=
                chatContainer.clientHeight + threshold;
            setIsAtBottom(isUserAtBottom);
            if (isUserAtBottom) {
                setNewMessageIndicator(false);
            }

            if (chatContainer.scrollTop < 50) {
                console.debug("Reached the top of the chat container");
                onScrollReachTop();
            }
        }
    }, [onScrollReachTop, chatContainer]);

    useEffect(() => {
        if (chatContainer) {
            chatContainer.addEventListener("scroll", handleScroll);
            return () => chatContainer.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    const sendMessage = async () => {
        if (inputRef.current) {
            const message = inputRef.current.value;
            if (message.trim() === "") return;
            inputRef.current.value = "";
            setIsSendingMessage(true);
            setShouldShowTypingMessage(true);
            await onUserMessageAdded(message);
            setIsSendingMessage(false);
            setTimeout(() => {
                if (isAtBottom) {
                    chatContainer.scrollTo({ top: chatContainer.scrollHeight, behavior: 'smooth' });
                }
            }, 0);
            if (inputRef && inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    const onkeydown = (e: any) => {
        if (e.key === "Enter" && !e.shiftKey) {
            sendMessage();
            e.preventDefault();
        }
    };

    const createNewConversation = useCallback(() => {
        onRequestNewConversation();
    }, [onRequestNewConversation]);


    useEffect(() => {
        setCopyright(agentInfo?.copyright || "");
    }, [agentInfo]);

    return (
        <div className="flex-grow h-full flex flex-col bg-gray-50">
            <div className={`w-full h-15 p-1 bg-blue-950 shadow-md ${!isMobileDevice() ? "rounded-xl" : ""} rounded-bl-none rounded-br-none`}>
                <div className="flex p-2 align-middle items-center">
                    {/* <div className="p-2 md:hidden rounded-full mr-1 hover:bg-purple-500 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                    </div> */}
                    <div className="rounded-full p-1/2">
                        <img
                            className="w-10 h-10 rounded-full"
                            src={
                                agentInfo?.botImgUrl ||
                                `${process.env.REACT_APP_STATIC_FILE_PATH}/logo192.png`
                            }
                            alt="avatar"
                        />
                    </div>
                    <div className="flex-grow p-2">
                        <div className="text-md text-gray-50 font-semibold">
                            {agentInfo?.name || "Conductify AI Agent"}
                        </div>
                        {/* <div className="flex items-center">
              <div className={`w-2 h-2 bg-green-300  rounded-full`}></div>
              <div className="text-xs text-gray-50 ml-1">Online</div>
            </div> */}
                    </div>
                    <div className="p-2 text-white cursor-pointer hover:bg-gray-500 rounded-full"></div>
                    <Menu as="div" className="relative inline-block text-left ">
                        <div>
                            <Menu.Button className="p-2 text-white cursor-pointer hover:bg-gray-500 rounded-full">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                    />
                                </svg>
                            </Menu.Button>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                onClick={createNewConversation}
                                                className={classNames(
                                                    active
                                                        ? "hover:bg-gray-100 text-white-900"
                                                        : "text-white-700",
                                                    "block px-4 py-2 text-sm"
                                                )}
                                            >
                                                New conversation
                                            </a>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
            {/* Chat container */}
            <div
                ref={(tag) => setChatContainer(tag)}
                className="w-full flex-grow bg-gray-50 p-4 overflow-y-auto"
            >
                {messages.map((message, index) => {
                    return message.role === "assistant" ? (
                        <AssistantMessage key={index} message={message} />
                    ) : (
                        <UserMessage key={index} message={message} />
                    );
                })}
                {shouldShowTypingMessage && (
                    <div className="flex items-end">
                        <div className="p-3 bg-gray-200 mx-3 my-1 rounded-2xl rounded-bl-none">
                            <div className='flex space-x-1 justify-center items-center'>
                                <span className='sr-only'>Loading...</span>
                                <div className='h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
                                <div className='h-2 w-2 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                                <div className='h-2 w-2 bg-gray-500 rounded-full animate-bounce'></div>
                            </div>
                        </div>
                    </div>
                )}
                {/* {newMessageIndicator && !isAtBottom && (
                    <div className="new-message-indicator">New messages</div>
                )} */}
            </div>
            <div className="px-4 py-2">
                <div className="p-1 bg-gray-100 border rounded-lg border-gray-300">
                    <div className="flex items-center">
                        <div className="search-chat flex flex-grow p-2">
                            <TextareaAutosize
                                onKeyDown={onkeydown}
                                ref={inputRef}
                                className={`disabled:cursor-not-allowed disabled:placeholder:text-slate-200 input text-gray-700 md:text-sm ${ isMobileDevice() ? "xs:text-base" : "text-sm" } focus:outline-none bg-gray-100 flex-grow`}
                                placeholder="Type a message..."
                                maxRows={3}
                                style={{ height: 24, resize: "none" }}
                                disabled={isSendingMessage}
                            />
                            <button
                                disabled={isSendingMessage}
                                onClick={sendMessage}
                                className="disabled:cursor-not-allowed disabled:text-slate-200 bg-gray-100  flex justify-center items-center text-gray-800"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-start items-center bg-gray-50 pb-2 px-4">
                    <div className="text-xs text-gray-500 italic">
                        { copyright ? <>Copyright by <strong>{agentInfo?.copyright}</strong></> : <div className="flex items-center"><span>Powered by</span><img src={`${process.env.REACT_APP_STATIC_FILE_PATH}/logo_full.png`} alt="logo" className="w-auto h-5 inline pl-1" /></div> }
                    </div>
                </div>
        </div>
    );
};

export default ChatBox;
