import { Route, Routes } from 'react-router-dom';
import ChatEmbed from './pages/ChatEmbed';
import './styles/style.css';
import Standalone from './pages/ChatStandalone';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Standalone />} />
      <Route path="/embed" element={<ChatEmbed />} />
      <Route path="/agent/:agentUuid" element={<Standalone />} />
    </Routes>
  );
}

export default App;